$( document ).ready(function() {
    _onthispage();
});

const _onthispage = () => {
    $('.onthispage nav').hide();
    if ($('[data-anchortext]').length > 0) {
        $('[data-anchortext]').each(function (i) {
            let pageSection = $(this),
                anchortext = pageSection.attr('data-anchortext'),
                anchorlink = pageSection.attr('data-anchorlink'),
                isChildNode = pageSection.closest('.data-anchortext-node').length,
                anchortextslug = '';
            if (anchortext && anchortext != '') {
                anchortextslug = slugify(anchortext);
                if ( $('html').hasClass('slice') ) {
                    pageSection.attr('id', anchortextslug).addClass('data-anchortext-node');
                    $('#onthispage ul').append('<li><a class="btn js-data-anchortext" href="#' + anchortextslug + '"><i class="fa-regular fa-chevron-right"></i><span class="sr-only">Scroll to </span>' + anchortext + '</a></li>');
                }
                else {
                    pageSection.attr('id', anchorlink).addClass('data-anchortext-node');
                }
            }
        });
        if ( $('.data-anchortext-node').length > 0 ) {
            $('.data-anchortext-node').each(function (i) {
                if ( !$(this).parent().hasClass('data-anchortext-node-wrapper') ) {
                    $(this).wrap('<span class="data-anchortext-node-wrapper"></span>');
                }
            });
        }
        $('.js-data-anchortext').on('click', function (e) {
            e.preventDefault();
            var _id = $(this).attr('href');
            var _offset = -$('header').height();
            var _element = document.getElementById(_id.replace('#', ''));
            var _y = _element.getBoundingClientRect().top + window.pageYOffset + _offset;
            window.scrollTo({top: _y, behavior: 'smooth'});
        });
        $('.js-onthispage-title').on('click', function (e) {
            e.preventDefault();
            let _this = $(this),
                _panel = _this.closest('.onthispage-panel');
            _panel.toggleClass('expanded');
            if ( _panel.hasClass('expanded') ) {
                _this.attr('aria-expanded', true);
                _panel.find('nav').slideDown('fast');
            }
            else {
                _this.attr('aria-expanded', false);
                _panel.find('nav').slideUp('fast');
            }
        });
        $('#onthispage').prev('section.wysiwyg').addClass('fullwidth');
        $('#onthispage').sticky({
            topSpacing: $('#sticky-header').height()
        });
    }
    else {
        $('#onthispage').remove();
    }
}